/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-12 13:37:30
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { deliveryType } from '@/utils/options';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 10,
    kname: 'contrNum',
    itemProps: {
      label: '合同编号：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        type: 'contractList',
        disableStatus: 1 },

      events: {
        change: ctx.selectContr } } },



  {
    span: 10,
    kname: 'contrName',
    itemProps: {
      label: '合同名称：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15 } } },



  {
    span: 10,
    kname: 'contrCo',
    itemProps: {
      label: '合作单位：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15 } } },



  {
    span: 10,
    kname: 'deliveryType',
    itemProps: {
      label: '送检类型：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        list: deliveryType,
        clearable: true } } },



  {
    span: 10,
    kname: 'saleName',
    itemProps: {
      label: '销售名称：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15 } } },



  {
    span: 10,
    kname: 'saleMobile',
    itemProps: {
      label: '销售电话：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15 } } },



  {
    span: 10,
    kname: 'custName',
    itemProps: {
      label: '客户名称：',
      rules: [genRules('require')],
      prop: 'custName' } },


  {
    span: 10,
    kname: 'custMobile',
    itemProps: {
      label: '客户电话：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 11,
        disabled: true } } },



  {
    span: 10,
    kname: 'custEmail',
    itemProps: {
      label: '报告邮箱：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15,
        disabled: true } } },



  {
    span: 10,
    kname: 'pkgCom',
    itemProps: {
      label: '快递公司：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15 } } },



  {
    span: 10,
    kname: 'pkgNum',
    itemProps: {
      label: '快递单号：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 15 } } },



  {
    span: 20,
    kname: 'comment',
    itemProps: {
      label: '备注：' },

    component: {
      name: 'el-input',
      props: {
        type: 'textarea',
        maxlength: 100 } } }];});





// form 默认值
export var formData = function formData() {return {};};


export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '样本名称',
    kname: 'title' },

  {
    label: '样本类型',
    kname: 'typeName' },

  {
    label: '物种',
    kname: 'createAt' },

  {
    label: '送样量',
    kname: 'createAt' },

  {
    label: '运输方式',
    kname: 'createAt' },

  {
    label: '备注',
    kname: 'createAt' },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};