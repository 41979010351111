var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticStyle: { overflow: "auto" }
    },
    [
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "div",
            { staticStyle: { width: "65%", margin: "20px 10%" } },
            [
              _c(
                "el-steps",
                { attrs: { active: 1, "align-center": "" } },
                [
                  _c("el-step", {
                    attrs: { title: "步骤 1", description: "填写基本信息" }
                  }),
                  _c("el-step", {
                    attrs: { title: "步骤 2", description: "填写样本信息" }
                  }),
                  _c("el-step", {
                    attrs: { title: "步骤 3", description: "完成" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                size: _vm.size,
                "label-width": _vm.labelWidth
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateSubmit($event)
                }
              }
            },
            [
              _c("c-form-row", {
                attrs: { list: _vm.models, readOnly: _vm.readOnly },
                scopedSlots: _vm._u([
                  {
                    key: "custName",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          _vm._b(
                            {},
                            "el-form-item",
                            scope.data.itemProps,
                            false
                          ),
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "请输入内容",
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.form.custName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "custName", $$v)
                                  },
                                  expression: "form.custName"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search"
                                  },
                                  on: { click: _vm.openCustomer },
                                  slot: "append"
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "c-box",
        {
          staticStyle: {
            "margin-bottom": "30px",
            display: "flex",
            "margin-left": "40%"
          },
          attrs: { slot: "header", flex: "row" },
          slot: "header"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.validateSubmit }
            },
            [_vm._v("下一步")]
          )
        ],
        1
      ),
      _c("FormDialog", { ref: "FormDialog", on: { refresh: _vm.getData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }